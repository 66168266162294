import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Email = makeShortcode("Email");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Sprechen Sie uns an!`}</h5>
    <address>
  Hitabis GmbH
  <br /> Hildegard-Jadamowitz-Straße 26
  <br /> 10243 Berlin
  <br /> Tel.: <a href="tel:+493028599044">+49(0)30 28 59 90 44</a>
  <br />
  Fax: +49(0)30 28 59 90 45
  <br />
  E-Mail: <Email email="info@hitabis.de" mdxType="Email" />
    </address>
    <p>{`Interesse an unseren Services? `}<br />{`
Senden Sie uns eine Nachricht. Wir rufen Sie gern zurück.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      